import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { navigate, graphql } from 'gatsby'
import {
  breakpoints,
  CLOrder,
  getAuth,
  getFullOrder,
  useAuth,
  useResolution,
  useShoppingCart,
  useLocation,
  log,
  ProductCart,
  listOrdersByCustomer,
  Tooltip,
  toCssPrefix,
  ZIndex,
  Country,
  showToast,
  getCartStateByOrderId,
} from '@ecommerce/shared'
import Layout from '../../components/Layout'
import { PgPageProps } from '../../types/PgPages'
import { UserMenuMobileNavbar } from '../../components/NavBar/UserMenuMobileNavbar'
import UserMenu from '../../templates/UserMenu'
import withPageTransition from '../../components/withPageTransition'
import TrackingWidget from '../../components/TrackingWdiget'
import { Icon } from '../../components/Icon/Icon'
import secrets from '../../config/secrets'
import OrderSummarySkeleton from '../../components/OrderSummary/OrderSummarySkeleton'
import EmptyOrdersList from '../../components/EmptyOrdersList'
import OrderSummary from '../../components/OrderSummary'
import ConfirmationAlert from '../../components/ConfirmationAlert'
import NoStockModal from '../../components/MyOrders/NoStockModal'

const { COUNTRY: country } = secrets
const isCL = secrets.COUNTRY === Country.CHILE

const { cssPrefix, toPrefix } = toCssPrefix(`MyOrders__`)

const Wrapper = styled.div<{ isEmpty?: boolean }>`
  .${cssPrefix} {
    &content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 340px;
      &-order-card {
        margin-bottom: 15px;
      }
    }

    &orders-period {
      align-self: flex-start;
      margin: -18px 0 15px;
      font-size: 15px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.medium};
      width: 100%;
      padding-bottom: 10px;
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &content {
        min-height: calc(100vh - 360px);
        width: 100%;
        max-height: ${({ isEmpty }) => (isEmpty ? '100%' : 'initial')};
      }
    }
  }
`

const TrackingSection = styled.div`
  margin: 8px auto 0;
  position: relative;
  width: 100%;

  .${cssPrefix}tracking {
    &-title {
      font-size: 15px;
      margin: 0;
    }

    &-text {
      margin: 0;
      display: flex;
      font-size: 13px;
    }

    &-help-text {
      position: relative;
      width: 16px;
      height: 16px;
      transition: 0.25s linear;

      &-tooltip {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 215px;
        right: -18px;
        top: 24px;
        transition: inherit;
        cursor: auto;
      }

      svg {
        width: 16px;
        height: 16px;
      }

      .${cssPrefix}tracking-help-text-tooltip:hover, svg:hover + .${cssPrefix}tracking-help-text-tooltip {
        opacity: 1;
        z-index: inherit;
      }

      &:hover {
        z-index: ${ZIndex.medium};
      }
    }

    &-widget {
      margin: 0 -20px;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix}tracking {
      &-title {
        font-size: 17px;
        margin-bottom: 5px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }
`

const MyOrders = (props: PgPageProps) => {
  const { currentCity, productCategories } = props.pageContext
  const currentSlug = currentCity && currentCity.slug ? currentCity.slug : 'vina-del-mar'
  const { toCurrency, isBolivia } = useLocation()
  const isBO = isBolivia()

  const {
    template: {
      title,
      ordersMaxAge = 15,
      noOrdersTopText,
      noOrdersImage: {
        file: { url: imageUrl },
      },
      noOrdersBottomText,
      noOrdersButtonText,
      noOrdersButtonSlug,
      repeatOrderText,
      repeatOrderConfirmText,
      noStockModalButtonText,
      noStockModalSubtitle,
      noStockModalTitle,
      errorMessage,
      qrBadgeText,
    },
    title: pageTitle,
  } = props.data.allContentfulPgPage.edges[0].node

  const { isDesktop } = useResolution()

  const showToastNotification = () => showToast({ content: errorMessage, type: 'error' })

  const {
    state: { firstName, lastName, ownerId },
  } = useAuth()
  const isAuth = getAuth()
  useEffect(() => {
    if (!isAuth) navigate(`/${currentSlug}`)
  }, [])

  const [orders, setOrders] = useState<CLOrder[]>([])
  const [isFetchingOrders, setIsFetchingOrders] = useState(true)
  const [refreshingOrderIds, setRefreshingOrderIds] = useState<string[]>([])
  const [repeatingOrderId, setRepeatingOrderId] = useState<string>()
  const [isOpenCart, setIsOpenCart] = useState(false)

  const [noStockProducts, setNoStockProducts] = useState<ProductCart[]>([])

  const [showRepeatOrderConfirmation, setShowRepeatOrderConfirmation] = useState(false)
  const [showCartConfirmationAlert, setShowCartConfirmationAlert] = useState(false)
  const repeatOrderId = useRef<string>()

  useEffect(() => {
    listOrdersByCustomer({ customerId: ownerId, country })
      .then((fetchedOrders) => {
        setOrders(fetchedOrders)
        setIsFetchingOrders(false)
      })
      .catch((e) => {
        log.error(e)
        showToastNotification()
      })
  }, [])

  const {
    state: { globalQuantity },
  } = useShoppingCart()

  const onOrderRefresh = async (orderId: string) => {
    try {
      setRefreshingOrderIds([...refreshingOrderIds, orderId])
      const updatedOrders = [...orders]
      const orderIndex = updatedOrders.findIndex((order) => order.id === orderId)

      updatedOrders[orderIndex] = await getFullOrder({ orderId, includeLineItems: true, country })
      setOrders(updatedOrders)
    } catch (e) {
      log.error(e)
    } finally {
      setRefreshingOrderIds(refreshingOrderIds.filter((id) => id !== orderId))
    }
  }

  const { replaceState, state } = useShoppingCart()

  const retryPayment = async (orderId: string) => {
    if (currentCity) {
      const {
        state: { byHash, globalQuantity: globalQuantityFromCartState, globalTotal, globalRawTotal },
      } = await getCartStateByOrderId(currentCity.commerceLayer.market.number, country, orderId)
      replaceState({ ...state, byHash, globalQuantity: globalQuantityFromCartState, globalRawTotal, globalTotal })
      setIsOpenCart(true)
    }
  }

  const checkCartBeforeRepeat = () => {
    const id = repeatOrderId.current

    setShowRepeatOrderConfirmation(false)
    if (globalQuantity !== 0) {
      setShowCartConfirmationAlert(true)
    } else if (id) retryPayment(id)
  }

  const onClickRepeatOrder = async (id: string) => {
    repeatOrderId.current = id

    setShowRepeatOrderConfirmation(true)
  }

  const onClickConfirmRepeatOrder = async () => {
    if (repeatOrderId.current) {
      setShowCartConfirmationAlert(false)
      retryPayment(repeatOrderId.current)
    }
  }

  const onCloseRepeatOrderCartAlert = () => setShowCartConfirmationAlert(false)
  const onCloseRepeatOrderConfirmation = () => setShowRepeatOrderConfirmation(false)

  const skeletonCards = [...Array(3)].map((el, i) => (
    <OrderSummarySkeleton key={i} fieldsNumber={4} className={`${cssPrefix}content-order-card`} />
  ))

  const renderOrder = (order: CLOrder) => (
    <OrderSummary
      order={{
        ...order,
        formattedAmountCents: toCurrency(
          order.attributes.gift_card_amount_cents
            ? order.attributes.total_amount_with_taxes_cents
            : order.attributes.total_amount_cents,
        ),
      }}
      className={`${cssPrefix}content-order-card`}
      key={order.id}
      isLoading={refreshingOrderIds.includes(order.id) || repeatingOrderId === order.id}
      onOrderRefresh={onOrderRefresh}
      onDetailsClick={(orderId: string) => navigate(`/${currentSlug}/my-orders/order?id=${orderId}`)}
      onPayClick={() => null}
      onRepeatClick={onClickRepeatOrder}
      data-test={`order-${order.id}`}
      qrBadgeText={qrBadgeText}
      disableActions={!!repeatingOrderId && repeatingOrderId !== order.id}
    />
  )

  const getContent = () => {
    if (isFetchingOrders) return skeletonCards
    if (orders && orders.length) return orders.map(renderOrder)
    return (
      <EmptyOrdersList
        topText={noOrdersTopText}
        imageUrl={imageUrl}
        bottomText={noOrdersBottomText}
        buttonText={noOrdersButtonText}
        onButtonClick={() => navigate(`/${currentCity.slug}/${noOrdersButtonSlug || ''}`)}
      />
    )
  }

  return (
    <Layout
      currentCity={currentCity}
      title={pageTitle}
      navbar={isDesktop ? undefined : UserMenuMobileNavbar({ city: currentCity, onLogoClick: () => navigate('/') })}
      categories={productCategories}
      openCart={isOpenCart}
    >
      {noStockProducts && noStockProducts.length !== 0 ? (
        <NoStockModal
          content={{ title: noStockModalTitle, subtitle: noStockModalSubtitle, buttonText: noStockModalButtonText }}
          onClose={() => {
            setNoStockProducts([])
            setRepeatingOrderId(undefined)
          }}
          products={noStockProducts}
          isBO={isBO}
        />
      ) : null}
      <Wrapper isEmpty={!isFetchingOrders && !orders.length}>
        {showCartConfirmationAlert && (
          <ConfirmationAlert
            text={repeatOrderText}
            confirmButtonText={repeatOrderConfirmText}
            onConfirm={onClickConfirmRepeatOrder}
            onCancel={onCloseRepeatOrderCartAlert}
            onClose={onCloseRepeatOrderCartAlert}
          />
        )}
        {showRepeatOrderConfirmation && (
          <ConfirmationAlert
            text="Los precios y stocks pudieron haber sufrido cambios"
            confirmButtonText="Continuar"
            onConfirm={checkCartBeforeRepeat}
            onCancel={onCloseRepeatOrderConfirmation}
            onClose={onCloseRepeatOrderConfirmation}
          />
        )}
        <UserMenu
          customerName={`${firstName} ${lastName}`}
          title={title}
          activeMenuIndex={3}
          currentCity={currentCity}
          contentClassName={`${cssPrefix}content`}
          titleClassName={`${cssPrefix}content-title`}
          cartHasProducts={globalQuantity > 0}
        >
          <>
            <p className={toPrefix('orders-period')}>
              Últimos&nbsp;
              {ordersMaxAge}
              &nbsp;días
            </p>
            <TrackingSection className={toPrefix('tracking')}>
              <h3 className={toPrefix('tracking-title')}>Sigue tu pedido</h3>
              <p className={toPrefix('tracking-text')}>
                Ingresa tu Nº de pedido&nbsp;
                <span className={toPrefix('tracking-help-text')}>
                  <Icon iconId="help-fill" />
                  <Tooltip tipPosition="right" className={toPrefix('tracking-help-text-tooltip')}>
                    Puedes encontrarlo en el detalle de tu pedido
                  </Tooltip>
                </span>
              </p>
              <TrackingWidget className={toPrefix('tracking-widget')} />
            </TrackingSection>
            {getContent()}
          </>
        </UserMenu>
      </Wrapper>
    </Layout>
  )
}

export default withPageTransition(MyOrders)

export const query = graphql`
  query MyOrdersQuery($myOrdersId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $myOrdersId } }) {
      edges {
        node {
          id
          title
          template {
            ... on ContentfulTmMyOrders {
              title
              ordersMaxAge
              noOrdersBottomText
              noOrdersButtonText
              noOrdersTopText
              noOrdersButtonSlug
              noOrdersImage {
                id
                file {
                  url
                }
              }
              repeatOrderText
              repeatOrderConfirmText
              noStockModalTitle
              noStockModalSubtitle
              noStockModalButtonText
              errorMessage
              qrBadgeText
            }
          }
        }
      }
    }
  }
`
